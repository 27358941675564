import "./style.css";
import React from "react";
import ReactDOM from "react-dom";
import { BrowserRouter as Router, Switch, Route, Redirect } from "react-router-dom";

import SideBar from "./components/sidebar/SideBar";
import About from "./components/pages/EN/About";
import Experience from "./components/pages/EN/Experience";
import Education from "./components/pages/EN/Education";
import Skills from "./components/pages/EN/Skills";
import Interests from "./components/pages/EN/Interests";
import Contact from "./components/pages/EN/Contact";

import Uber from "./components/pages/DE/Uber";
import Erfahrungen from "./components/pages/DE/Erfahrungen";
import Bildungsbiografie from "./components/pages/DE/Bildungsbiografie";
import Kompetenzen from "./components/pages/DE/Kompetenzen";
import Interessen from "./components/pages/DE/Interessen";
import Kontakt from "./components/pages/DE/Kontakt";
import Footer from "./components/footer/Footer";
import Page404 from "./components/pages/404/Page404";
import PrivacyPolicy from "./components/pages/privacypolicy/PrivacyPolicy";

const App = () => {
  return (
    <Router>
      <SideBar />

      <div className="container-fluid p-0">
        <Switch>
          <Route exact path="/privacypolicy">
            <PrivacyPolicy />
          </Route>
          <Route exact path="/en/about">
            <About />
          </Route>
          <Route exact path="/de/about">
            <Uber />
          </Route>
          <Route exact path="/en/experience">
            <Experience />
          </Route>
          <Route exact path="/de/experience">
            <Erfahrungen />
          </Route>
          <Route exact path="/en/education">
            <Education />
          </Route>
          <Route exact path="/de/education">
            <Bildungsbiografie />
          </Route>
          <Route exact path="/en/skills">
            <Skills />
          </Route>
          <Route exact path="/de/skills">
            <Kompetenzen />
          </Route>
          <Route exact path="/en/interests">
            <Interests />
          </Route>
          <Route exact path="/de/interests">
            <Interessen />
          </Route>
          <Route exact path="/en/contact">
            <Contact />
          </Route>
          <Route exact path="/de/contact">
            <Kontakt />
          </Route>
          <Route path="/">
            <Redirect to="/de/about" />
            {/* <Page404 /> */}
          </Route>
        </Switch>
        <Footer />
      </div>
    </Router>
  );
};

ReactDOM.render(<App />, document.getElementById("root"));
