import "./../contact.scss";
import React, { useRef, useEffect, useState } from "react";
import { ticTacToe } from "i-am-not-a-robot";

const Contact = () => {
  const nameRef = useRef();
  const emailRef = useRef();
  const messageRef = useRef();

  const successRef = useRef();
  const dangerRef = useRef();

  const captchaRef = useRef();

  const [human, setHuman] = useState(false);

  useEffect(() => {
    ticTacToe(captchaRef.current, () => {
      setHuman(true);
    });
  }, []);

  const submitBtnClick = (e) => {
    e.preventDefault();

    //validation
    const nameValidate =
      nameRef.current.value.length > 2 &&
      nameRef.current.value.length <= 20 &&
      nameRef.current.value.match(
        /^(([A-Za-z]+[\-\']?)*([A-Za-z]+)?\s)+([A-Za-z]+[\-\']?)*([A-Za-z]+)?$/
        /* 
        Here is the outcome on several possible pieces of input:

            "Jon Doe": true
            "Jonathan Taylor Thomas": true
            "Julia Louis-Dreyfus": true
            "Jean-Paul Sartre": true
            "Pat O'Brien": true
            "Þór Eldon": false
            "Marcus Wells-O'Shaugnessy": true
            "Stephen Wells-O'Shaugnessy Marcus": true
            "This-Is-A-Crazy-Name Jones": true
            "---- --------": false
            "'''' ''''''''": false
            "'-'- -'-'-'-'": false
            "a-'- b'-'-'-'": false
            "'-'c -'-'-'-d": false
            "e-'f g'-'-'-h": false
            "'ij- -klmnop'": false

        */
      );
    if (!nameValidate) {
      nameRef.current.classList.add("invalid");
      nameRef.current.classList.remove("valid");
      nameRef.current.nextElementSibling.classList.add("show");
    } else {
      nameRef.current.classList.add("valid");
      nameRef.current.classList.remove("invalid");
      nameRef.current.nextElementSibling.classList.remove("show");
    }

    const emailValidate =
      emailRef.current.value.length > 4 &&
      emailRef.current.value.length <= 50 &&
      emailRef.current.value.match(
        /^[a-zA-Z0-9.!#$%&’*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/
      ); // regex
    if (!emailValidate) {
      emailRef.current.classList.add("invalid");
      emailRef.current.classList.remove("valid");
      emailRef.current.nextElementSibling.classList.add("show");
    } else {
      emailRef.current.classList.add("valid");
      emailRef.current.classList.remove("invalid");
      emailRef.current.nextElementSibling.classList.remove("show");
    }

    const messageValidate =
      messageRef.current.value.length > 5 &&
      messageRef.current.value.length <= 160;
    if (!messageValidate) {
      messageRef.current.classList.add("invalid");
      messageRef.current.classList.remove("valid");
      messageRef.current.nextElementSibling.classList.add("show");
    } else {
      messageRef.current.classList.add("valid");
      messageRef.current.classList.remove("invalid");
      messageRef.current.nextElementSibling.classList.remove("show");
    }

    // send data
    if (human && nameValidate && emailValidate && messageValidate) {
      const sendData = {
        name: nameRef.current.value,
        email: emailRef.current.value,
        message: messageRef.current.value,
        language: "En",
      };
      fetch("/contact", {
        method: "POST",
        headers: {
          "content-type": "application/json",
        },
        body: JSON.stringify(sendData),
      })
        .then((res) => {
          if (res.status === 200) {
            res
              .json()
              .then((data) => {
                if (data === 1) {
                  // form reset
                  nameRef.current.value = "";
                  emailRef.current.value = "";
                  messageRef.current.value = "";
                  // show success message
                  successRef.current.classList.remove("d-none");
                  dangerRef.current.classList.add("d-none");
                } else {
                  // show error message
                  successRef.current.classList.add("d-none");
                  dangerRef.current.classList.remove("d-none");
                }
              })
              .catch(() => {
                // show error message
                successRef.current.classList.add("d-none");
                dangerRef.current.classList.remove("d-none");
              });
          } else {
            // show error message
            successRef.current.classList.add("d-none");
            dangerRef.current.classList.remove("d-none");
          }
        })
        .catch(() => {
          // show error message
          successRef.current.classList.add("d-none");
          dangerRef.current.classList.remove("d-none");
        });
    } else {
      // show error message
      successRef.current.classList.add("d-none");
      dangerRef.current.classList.remove("d-none");
    }
  };

  return (
    <>
      <section className="resume-section" id="contact">
        <div className="container ">
          <div className="d-flex justify-content-center">
            <div className="col-md-6 col-md-offset-3">
              <div className="well well-sm">
                <form className="form-horizontal " action="" method="post">
                  <fieldset>
                    <h3 className="text-center text-primary h2">Contact me</h3>

                    <div
                      className="alert alert-success d-none"
                      role="alert"
                      ref={successRef}
                    >
                      Your message has been sent successfully,
                      <br />
                      i'll contact you as soon as possible :)
                    </div>

                    <div
                      className="alert alert-danger d-none"
                      role="alert"
                      ref={dangerRef}
                    >
                      Can't send your message :(
                      <br />
                      please try again later ...
                    </div>

                    <div className="form-group">
                      <label className=" control-label h4" for="name">
                        Name
                      </label>
                      <input
                        id="name"
                        name="name"
                        type="text"
                        placeholder="Your name"
                        className="form-control"
                        ref={nameRef}
                      />
                      <div className="invalid-feedback">
                        Name should be between 3 & 50 letters only !
                      </div>
                    </div>

                    <div className="form-group">
                      <label className=" control-label h4" for="email">
                        E-mail
                      </label>
                      <input
                        id="email"
                        name="email"
                        type="text"
                        placeholder="Your email"
                        className="form-control"
                        ref={emailRef}
                      />
                      <div className="invalid-feedback">
                        Should be actual e-mail address !
                      </div>
                    </div>

                    <div className="form-group">
                      <label className=" control-label h4" for="message">
                        Your message
                      </label>
                      <textarea
                        className="form-control"
                        id="message"
                        name="message"
                        placeholder="Please enter your message here..."
                        rows="5"
                        ref={messageRef}
                      ></textarea>
                      <div className="invalid-feedback">
                        message should be between 5 & 160 letters only !
                      </div>
                    </div>

                    <div className="form-group">
                      <label className=" control-label h4">
                        Play TicTacToe to Submit
                      </label>
                      <div ref={captchaRef}></div>
                    </div>

                    <div className="form-group">
                      <button
                        type="submit"
                        className="btn btn-primary btn-lg"
                        onClick={(e) => {
                          submitBtnClick(e);
                        }}
                        disabled={!human}
                      >
                        Submit
                      </button>
                    </div>
                  </fieldset>
                </form>
              </div>
            </div>
          </div>
        </div>
      </section>
      <hr className="m-0" />
    </>
  );
};


export default Contact;