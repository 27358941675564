import React from "react";
import { Link } from "react-router-dom";

const Footer = () => {
  return (
    <footer
      className="bg-transparent
		text-center py-2 text-primary"
    >
      <span>copyrights© 2021 w@ell</span>
      <span>&nbsp;-&nbsp;</span>
      <span>
        <Link to="/privacypolicy">Privacy Policy</Link>
      </span>
    </footer>
  );
};

export default Footer;