import React from 'react';

const Interessen = () => {
    return (
        <>
            <section className="resume-section" id="interests">
                <div className="resume-section-content">
                    <h2 className="mb-5 text-primary">Interessen</h2>
                    <p>Neben meiner Tätigkeit als Webentwickler in Leipzig genieße ich einen Großteil meiner Zeit draußen. Ich bin ein begeisterter Fahrradfahrer. Während der warmen Monate hier in Leipzig genieße ich lange Radtouren, Spaziergänge in den Wäldern und die Nähe zum Wasser.</p>
                    <p className="mb-0">Wenn es das Wetter oder der Lockdown nicht zulassen, Zeit draußen zu verbringen, schaue ich Filme oder Serien. Ich bin sehr daran interessiert herauszufinden, wie Dinge funktionieren und sie zu reparieren, wenn etwas kaputt ist. Das war schon als Kind mein Hobby.</p>
                </div>
            </section>
            <hr className="m-0" />
        </>
    )
};

export default Interessen;