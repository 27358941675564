import React from "react";

const Uber = () => {
  return (
    <>
      <section className="resume-section" id="about">
        <div className="resume-section-content">
          <h1 className="mb-0">
            MHD Waell
            <span className="text-primary">&nbsp;Al Omari</span>
          </h1>
          <div className="subheading mb-5">
            Arthur-Hoffmann-Str. 94, 04275 Leipzig · +49 (176) 638-62579 ·
            <a target="_blank" rel="noreferrer" href="mailto:name@email.com">
              waellomari@gmail.com
            </a>
          </div>
          <p className="lead mb-5">
            Junior Full-Stack-Entwickler mit einjähriger Grunderfahrung während
            eines Kurses des Digital Career Institute. Computertechniker mit
            über 15-jähriger Erfahrung in der Computerwartung, sowohl in
            Selbstständigkeit als auch in staatlicher Anstellung für das
            syrische Ministerium für Elektrizität. Derzeit im Prozess
            befindlich, durch die Kombination aus Arbeitserfahrung und
            Weiterbildung in Europa Fuß zu fassen nach der Flucht aus Syrien
            aufgrund des Bürgerkriegs.
          </p>
          <div className="social-icons">
            <a
              className="social-icon"
              target="_blank"
              rel="noreferrer"
              href="https://www.linkedin.com/in/waellomari/"
            >
              <i className="fab fa-linkedin-in"></i>
            </a>
            <a
              className="social-icon"
              target="_blank"
              rel="noreferrer"
              href="https://github.com/waellomari"
            >
              <i className="fab fa-github"></i>
            </a>
            <a
              className="social-icon"
              target="_blank"
              rel="noreferrer"
              href="https://www.facebook.com/waellomari"
            >
              <i className="fab fa-facebook-f"></i>
            </a>
            <a
              className="social-icon"
              target="_blank"
              rel="noreferrer"
              href="https://www.instagram.com/waellomari/"
            >
              <i className="fab fa-instagram"></i>
            </a>
            <a
              className="social-icon"
              target="_blank"
              href="/CV/WaellOmariCvDe.pdf"
            >
              <i className="fa fa-file-download"></i>
            </a>
          </div>
        </div>
      </section>
      <hr className="m-0" />
    </>
  );
};


export default Uber;