import React from 'react';
import { useLocation } from 'react-router-dom';
import SideBarEN from '../pages/EN/SideBar'
import SideBarDE from '../pages/DE/SideBar'
import './sidebar.scss';

const SideBar = () => {
    //const history = useHistory()
    const { pathname } = useLocation()
    const language = pathname.substr(1, 2);

    if (language === 'en') {
        return <SideBarEN />
    }
    else return <SideBarDE />

}


export default SideBar;