import React from "react";


const About = () => {
  return (
    <>
      <section className="resume-section" id="about">
        <div className="resume-section-content">
          <h1 className="mb-0">
            MHD Waell
            <span className="text-primary">&nbsp;Al Omari</span>
          </h1>
          <div className="subheading mb-5">
            Arthur-Hoffmann-Str. 94, 04275 Leipzig · +49 (176) 638-62579 ·
            <a
              href="mailto:name@email.com"
              target="_blank"
              rel="noreferrer"
            >
              waellomari@gmail.com
            </a>
          </div>
          <p className="lead mb-5">
            Junior Full-stack Programmer with one-year basic experience during a
            course at Digital Career Institute. Computer technician with over 15
            years experience in computer maintenance both as an independent
            entrepreneur and in government, working for the Syrian Ministry of
            Electricity. Now looking to use my experience and expertise in
            Europe having had to flee Syria because of the conflict.
          </p>
          <div className="social-icons">
            <a
              className="social-icon"
              target="_blank"
              rel="noreferrer"
              href="https://www.linkedin.com/in/waellomari/"
            >
              <i className="fab fa-linkedin-in"></i>
            </a>
            <a
              className="social-icon"
              target="_blank"
              rel="noreferrer"
              href="https://github.com/waellomari"
            >
              <i className="fab fa-github"></i>
            </a>
            <a
              className="social-icon"
              target=" blank"
              href="https://www.facebook.com/waellomari"
            >
              <i className="fab fa-facebook-f"></i>
            </a>
            <a
              className="social-icon"
              target="_blank"
              rel="noreferrer"
              href="https://www.instagram.com/waellomari/"
            >
              <i className="fab fa-instagram"></i>
            </a>
            <a
              className="social-icon"
              target="_blank"
              rel="noreferrer"
              href="/CV/WaellOmariCvEn.pdf"
            >
              <i className="fa fa-file-download"></i>
            </a>
          </div>
        </div>
      </section>
      <hr className="m-0" />
    </>
  );
};


export default About;